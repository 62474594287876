#donate-form {
  padding: 0 0 2em;
  h1 {
    font-size: 1.5em;
    font-weight: bold;
  }
}

.form-content {
  margin: auto;
  max-width: 30em;
  width: 100%;
  aside {
    color: #acacac;
    font-size: .75em;
    padding: 1em;
    position: absolute;
    right: 0;
    text-transform: uppercase;
    top: 0;
    .icon-lock {
      opacity: .31;
      vertical-align: -1px;
    }
  }
}

.form-group {
  margin-bottom: 0;
}

.input-xl {
  font-size: 2.2em;
}

input.input-xl, .input-xl input {
  border: 6px solid $brand-color-5;
  border-radius: 0;
  height: auto;
  padding: .2em .4em;
  @include transition(border-color .15s);
  &:active, &.active, &:focus {
    border-color: $brand-color-2 !important;
    outline: 0;
  }
}

.input-currency {
  position: relative;
  &:before {
    content: "\20ac"; //€
    left: 1.9375rem; // 31px
    position: absolute;
    top: .875rem; // 14px
  }
  input {
    max-width: 100%;
    padding-left: 2.625rem; // 42px
  }
  &[data-length="3"] {
    &:before {
      left: 1.375rem // 22px;
    }
    input {
      padding-left: 2.0625rem // 33px;
    }
  }
  &[data-length="4"] {
    &:before {
      left: 1rem; // 16px;
    }
    input {
      padding-left: 1.6875rem; // 27px;
    }
  }
}

.btn .icon {
  margin-left: .2em;
  position: relative;
  top: .1667em;
}

.loading .icon {
  font-size: .8em;
  opacity: .4;
  position: relative;
  top: .1em;
}

.error-block {
  color: $state-danger-text;
  margin: .625em 0 -.3125em;
  @include scale(0);
  @include transition(transform .1s);
  visibility: hidden;
  &.active {
    @include scale(1);
    visibility: visible;
  }
}

#donate-btns {
  flex-grow: 2;
  max-width: 15.3125rem;
  margin: 0 auto;
  & > * {
    float: left;
    font-size: 1.875em;
    margin-bottom: 1.2rem;
    width: 46%;
    width: calc(50% - .6rem);
  }
  button {
    border-width: 0;
    @include button-variant(#000, $brand-color-5, $brand-color-5);
    padding-top: .9375rem;
    padding-bottom: .9375rem;
    @include transition-property(background-color, border-color, color);
    @include transition-duration(.15s);
    &:hover {
      color: #000;
      background-color: $brand-color-5;
      border-color: $brand-color-5;
    }
    &[aria-pressed] {
      color: #fff !important;
      background-color: $brand-color-2 !important;
      border-color: $brand-color-2 !important;
      @include box-shadow(none !important);
    }
  }
  input {
    border: .375rem solid $brand-color-5;
    height: 4.3125rem;
    padding-top: .5625rem;
    padding-bottom: .5625rem;
    @include transition(border-color .15s);
  }
  .active input {
    border-color: $brand-color-2 !important;
  }
  & > :nth-child(odd) {
    margin-right: .6rem;
  }
  & > :nth-child(even) {
    margin-left: .6rem;
  }
  & :nth-last-child(-n + 2) {
    margin-bottom: 0;
  }
}

.payment-options {
  margin: 0 auto 1em;
  max-width: 20em;
  @include transform(translateX(0));
  @include transition(transform .25s ease-in-out);
  .wrap {
    position: relative;
    @include transition(width .25s ease-in-out);
    width: 100%;
  }
  .method {
    border: 1px solid transparent;
    border-radius: 1em;
    @include box-shadow(inset 0 0 1.5em rgba(0,0,0,.1), 0 1px 5px rgba(0,0,0,.25));
    opacity: 1;
    padding: .7em .5em;
    @include transition(all .25s, border .15s);
    @include transition-timing-function(ease-in-out);
    @include transform(scale(1) translateX(0));
    @include transform-origin(left);
    &:active, &:focus, &:hover {
      border-color: #7caadd;
      @include box-shadow(inset 0 0 1.5em rgba(0,0,0,.1), 0 1px 5px rgba(0,0,0,.25), inset 0 1px 1px rgba(76,107,153,.15), 0 0 8px #326cc0);
      outline: none;
    }
    &[disabled] {
      margin-right: 0;
      opacity: 0;
      @include transform(scale(0) translateX(0));
    }
  }
  button.method {
    background: white;
    color: $brand-color-2;
    font-size: 1.25em;
    font-weight: bold;
    padding-top: 1.4em;
    padding-bottom: 1.4em;
    &.padless {
      padding-top: .8em;
      padding-bottom: .8em;
    }
  }
  .cancel {
    background-color: transparent;
    border: 0;
    left: 52%;
    opacity: .4;
    position: absolute;
    top: 50%;
    @include transform(scale(.75) translateY(-50%));
    @include transform-origin(left);
    @include transition-property(left, opacity, transform);
    @include transition-timing-function(ease-in-out);
    @include transition-duration(.2s);
    &.delayed {
      @include transition-delay(.05s);
    }
    &[disabled] {
      opacity: 0;
      @include transform(scale(0) translateY(-50%));
    }
  }
}

.payment-options--hori {
  .method {
    float: left;
    width: 48%;
    &:first-child {
      margin-right: 4%;
    }
  }


  &.card {
    .wrap {
      width: 13em;
    }
    .method {
      border-radius: 0;
      padding: 0 .25em;
    }
    .direct-debit {
      @include transition(none);
    }
    .card {
      @include transform(scale(1) translateX(-100%));
    }
  }
  &.direct-debit {
    @include transform(translateX(25%));
  }
}

.payment-options--vert {
  .method {
    display: block;
    float: none;
    margin: .6em auto;
    width: 67%;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .cancel {
    left: 72%;
  }

  &.selected {
    .wrap {
      width: 9.75em;
    }
    .method {
      border-radius: 0;
      font-size: .8em;
      margin: 0;
      padding: 0 .25em !important;
    }
    .method[disabled] {
      border: 0;
      height: 0;
      padding: 0;
      @include transition(none);
      width: 0;
    }
    .method[aria-pressed=true] {
      @include transform(scale(1));
    }
  }
}

.payment-details {
  height: 29.6em;
  .anim-wrap {
    margin: 0 auto;
    max-width: 20em;
    @include transition-delay(.05s);
  }
  .xcheckbox {
    float: left;
    margin: .6em 0;
    padding: .6em 0;
  }
  .error-block {
    float: left;
    width: 100%;
  }
}

.persp {
  perspective: 300px;
  @include transition(height .25s ease-in-out);
}

.anim-wrap {
  opacity: 1;
  &.swing-top {
    @include rotateX(0deg);
    @include transform-origin(top);
    @include transition(all .25s ease-in-out);
  }
  &.scale {
    @include scale(1);
    @include transition(opacity .15s ease-in-out, transform .15s ease-in-out);
  }
}

.ho { // hide overflow
  overflow: hidden;
}

.hc { // hide content
  height: 0 !important;
  visibility: hidden;
  .anim-wrap {
    opacity: 0;
    &.swing-top {
      @include rotateX(-60deg);
    }
    &.scale {
      @include scale(0);
    }
  }
}

.start {
  flex-grow: 2;
}

.finish {
  display: none;
  .message {
    display: none;
  }
}

.input {
  display: inline-block;
  float: left;
  position: relative;
  width: 100%;
  .form-control {
    font-size: 1em;
    height: auto;
    padding: .5em .8em .5em 2em;
    position: relative;
    &:active, &:focus {
      border-color: #7caadd !important;
      border-radius: 4px !important;
      @include box-shadow(inset 0 1px 1px rgba(76,107,153,.15), 0 0 8px #326cc0 !important);
      z-index: 1;
    }
  }
  .icon {
    left: .6em;
    line-height: 1;
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
    z-index: 2;
  }
  &.no-icon .form-control {
    padding-left: .8em;
  }
}

.input.half {
  width: 50%;
  width: calc(50% + 1px);
  .form-control {
    border-right-color: #eee;
  }
}
.input.half + .input.half {
  margin-left: -1px;
  width: 50%;
  .form-control {
    border-right-color: #ccc;
    border-left-color: #eee;
  }
}

.input.top {
  .form-control {
    border-bottom-color: #eee;
    border-radius: 4px 4px 0 0;
    margin-bottom: -1px;
  }
  &.half .form-control {
    border-radius: 4px 0 0 0;
  }
  .input.half.top + &.half {
    .form-control {
      border-radius: 0 4px 0 0;
    }
  }
}
.input.bottom + .input.top {
  margin-top: 1.2em;
}

.input.middle {
  .form-control {
    border-top-color: #eee;
    border-bottom-color: #eee;
    border-radius: 0;
    @include box-shadow(none);
    margin-bottom: -1px;
  }
}

.input.bottom {
  .form-control {
    border-top-color: #eee;
    border-radius: 0 0 4px 4px;
    @include box-shadow(none);
  }
  &.half .form-control {
    border-radius: 0 0 0 4px;
  }
  .input.half.bottom + &.half {
    .form-control {
      border-radius: 0 0 4px 0;
    }
  }
}
