@media only screen and (min-width: 20em) { // 320px

  #backdrop-xs {
    .overlay {
      font-size: calc(1.75em + 4.5vw - 14.4px);
    }
  }

}


@media only screen and (min-width: 48em) { // 768px

  .lead {
    font-size: 1.125em;
  }

  .btn-wide {
    @include box-shadow(0 1px 2px rgba(0,0,0,.3));
  }

  // Layout

  header {
    background: transparent;
  }

  #logo {
    // background: rgba(255,255,255,.55);
    float: right;
    max-width: 350px;
    padding: 10px;
  }

  #backdrop {
    background-color: $brand-color-4;
    background-position: 50% 0%;
    background-repeat: no-repeat;
    background-size: cover;
    &:before, &:after {
      border-bottom-color: white;
    }
  }

  #donate-form {
    background-color: rgba(255,255,255,.8);
    @include box-shadow(0 1.6em 1em 0 rgba(0,0,0,.2));
    color: $text-color;
    margin-bottom: 4em;
    margin-left: 2em;
    padding: 0;
    text-shadow: none;
    @include transition(margin-bottom .15s ease-in-out);
    width: 30em;
  }

  .popup-container {
    display: inline-block;
    position: relative;
  }

  .popup {
    @include box-shadow(0 .5em 1em rgba(0,0,0,.2));
    height: auto;
    left: 100%;
    margin: 0 0 0 2em;
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));

    &:after {
      border: .6em solid white;
      @include box-shadow(0 0 .6em rgba(0,0,0,.2));
      content: '';
      display: block;
      height: 0;
      left: 0;
      margin-top: -.8485em;
      position: absolute;
      top: 50%;
      @include transform(rotate(45deg));
      @include transform-origin(0 0);
      width: 0;
      z-index: -1;
    }
  }

  .popup-content {
    background-color: white;
    position: relative;
    width: 25em;
    z-index: 1;
  }

  .payment-options {
    margin-top: 1em;
    input {
      background-color: #fff;
    }
    .method {
      @include box-shadow(0 1px 1px rgba(0,0,0,.15));
    }
  }

  .no-transition {
    @include transition(none !important);
  }

  .tab-links {
    background-color: rgba(255,255,255,.58);
  }

  .tab-link {
    border-bottom: 2px solid transparent;
    color: $text-color;
    display: block;
    font-size: .875em;
    font-weight: normal;
    padding: 1.4em 1em;
    text-align: center;
    text-transform: none;
    &:before {
      background-color: $brand-color-2;
      border-radius: 50%;
      color: white;
      content: counter(tabs);
      display: inline-block;
      height: 2em;
      line-height: 2em;
      text-align: center;
      width: 2em;
    }
    &.active {
      border-bottom-color: $brand-color-2;
    }
  }

  .tabs {
    section {
      min-height: 24em;
    }
  }

  // Forms

  .form-content {
    position: relative;
    aside {
      color: #5c5c5c;
      padding: 1rem;
      right: .4rem;
    }
  }

  .intro {
    background-color: transparent;
    padding-bottom: 0;
    &:before, &:after {
      display: none;
    }
    p {
      margin-right: 3.75rem;
      margin-left: 3.75rem;
    }
  }

  blockquote {
    padding: .625rem 1.875rem;
    text-align: left;
    footer {
      padding-top: 0;
    }
  }

  .media-left {
    display: table-cell;
    padding-right: 1em;
  }

  .media-right {
    padding: 0 0 0 1em;
  }

}

@media only screen and (min-width: 100em) { // 1600px
  html {
    font-size: 112.5%;
  }
}
